import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingComponent} from './app-routing.component';
import {HttpClientModule} from '@angular/common/http';
import {HttpService} from './shared/service/http.service';
import {FormsModule} from '@angular/forms';
import localeCs from '@angular/common/locales/cs';
import {CurrencyPipe, registerLocaleData} from '@angular/common';
import {SharedModule} from './shared/shared.module';
import {NgToggleModule} from 'ng-toggle-button';
import {NgSelectModule} from '@ng-select/ng-select';
import {MatDialogModule} from '@angular/material/dialog';
import {GoogleTagManagerModule} from 'angular-google-tag-manager';

registerLocaleData(localeCs);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingComponent,
    HttpClientModule,
    FormsModule,
    SharedModule,
    NgToggleModule,
    NgSelectModule,
    MatDialogModule,
    GoogleTagManagerModule.forRoot({
      id: 'GTM-KXT7VRRQ',
    }),
  ],
  providers: [
    HttpService,
    CurrencyPipe
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
