import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';

const routes: Routes = [
  {
    path: '',
    loadChildren:  () => import('./modules/neo-calculator/neo-calculator.module').then(mod => mod.NeoCalculatorModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingComponent{}
